import { Component, OnInit, OnDestroy, Input, HostListener } from '@angular/core';
import { MessageService, SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { FreeTextRowAnswer } from '../free-text-row-answer';
import { QuestionResponse, QuestionAnswerInstanceResponse, ColumnType, SelectedOptionRequest } from '../../public-api';
import { SurveyStateService } from '../../public-api';
import { QuestionComponent } from '../question.component';

interface TextAnswer {
  text: string;
  answerOptionId: number;
}

@Component({
  selector: 'app-matrix-dropdown-question',
  templateUrl: './matrix-mixed-question.component.html',
  styleUrls: ['./matrix-mixed-question.component.scss']
})
export class MatrixMixedQuestionComponent implements OnInit, OnDestroy, QuestionComponent {
  @Input() questionData: QuestionResponse;
  @Input() questionAnswerInstanceList: QuestionAnswerInstanceResponse[];
  @Input() messageService: MessageService;
  @Input() surveyStateService: SurveyStateService;
  @Input() readonly: boolean;

  textAnswers: FreeTextRowAnswer[] = [];

  rowTooltips = [];
  columnTooltips = [];
  
  selectedAnswerOptionIdList: { label: string, value: number }[][] = [];
  selectedOptionsForCell: SelectItem[][][] = [];
  providedTextListMatrix: TextAnswer[][] = [];
  orderedColumnNames = [];
  orderedRowNames = [];
  showComponent = false;

  columnTypes: ColumnType[] = [];
  columnTypeEnum = ColumnType;

  private answerSavedSubscription: Subscription;
  constructor() { }
  
  ngOnInit() {
    this.prepareData();
    this.showComponent = this.surveyStateService?.checkVisibilityCondition(this.showComponent, this.questionData.dependsOnAnswerOptionIdCollection, this.questionData.id) ?? true;
    
    this.textAnswers = new Array(this.orderedRowNames.length);
    this.questionData.matrixRowConfigurationCollection.filter(config => config.freeTextAnswer).forEach((row, index) => {
      const answerOptionId = row.freeTextAnswer.answerOptionCollection[0].id;
      const questionAnswerInstanceForFreeText = this.questionAnswerInstanceList.filter(a => a.answerOptionSelectedId === answerOptionId)[0];
      const freeText = questionAnswerInstanceForFreeText != null ? questionAnswerInstanceForFreeText.freeTextAnswer : '';
      this.textAnswers[row.matrixRowId - 1] = {
        freeText: freeText,
        answerOptionId: answerOptionId
      };
    });

    this.rowTooltips = new Array(this.orderedRowNames.length);
    this.questionData.matrixRowConfigurationCollection.filter(config => config.tooltipText).forEach((row, index) => {
      this.rowTooltips[row.matrixRowId - 1] = row.tooltipText;
    });
    
    this.columnTooltips = new Array(this.orderedRowNames.length);
    this.questionData.matrixColumnConfigurationCollection.filter(config => config.tooltipText).forEach((column, index) => {
      this.columnTooltips[column.matrixColumnId - 1] = column.tooltipText;
    });
    if (!this.readonly) {
      this.answerSavedSubscription = this.surveyStateService.answerSaved.subscribe(
        data => {
          if (data.successStatus) {
            if (data.successStatus) {
              this.showComponent = this.surveyStateService.checkVisibilityCondition(this.showComponent, this.questionData.dependsOnAnswerOptionIdCollection, this.questionData.id);
              if (this.showComponent === false) {
              }
            }
          }
        });
    }
  }

  prepareData() {
    this.questionData.answerCollection = this.questionData
      .answerCollection
      .filter(a => a.matrixRowId != null && a.matrixColumnId != null) // filter out answers which are not in matrix (are row input text) 
      .sort((a, b) =>
      a.matrixRowId - b.matrixRowId || a.matrixColumnId - b.matrixColumnId);

    this.orderedColumnNames = this.questionData.matrixColumnConfigurationCollection.sort(colConfig => colConfig.matrixColumnId).map(colConfig => colConfig.matrixColumnText)
    this.orderedRowNames = this.questionData.matrixRowConfigurationCollection.sort(rowConfig => rowConfig.matrixRowId).map(colConfig => colConfig.matrixRowText)
  

    // this.columnTypes.push(new Array(this.orderedColumnNames.length));
    this.questionData.matrixColumnConfigurationCollection.forEach(columnConfiguration => {
      this.columnTypes[columnConfiguration.matrixColumnId - 1] = columnConfiguration.columnType;
    });

    this.orderedRowNames.forEach((rowName, iIndex) => {
      this.selectedAnswerOptionIdList.push(new Array(this.orderedRowNames.length));
      this.providedTextListMatrix.push(new Array(this.orderedRowNames.length));
      this.selectedOptionsForCell.push(new Array(this.orderedRowNames.length));
      this.orderedColumnNames.forEach((colName, jIndex) => {
        const answerOption = this.questionData.answerCollection
          .filter(a => a.matrixRowId === iIndex + 1 && a.matrixColumnId === jIndex + 1);
        if (answerOption.length === 0) {
          this.selectedAnswerOptionIdList[iIndex][jIndex] = null;
          return;
        }

        const answerOptionIdsInCell = answerOption[0]
          .answerOptionCollection.map(a => a.id);
          
        if (this.columnTypes[jIndex] == ColumnType.Dropdown) {
          var selectedAnswerOptionId = answerOptionIdsInCell.filter(a => this.questionAnswerInstanceList.map(b => b.answerOptionSelectedId).includes(a))[0];
          selectedAnswerOptionId = selectedAnswerOptionId ? selectedAnswerOptionId : 0
          this.selectedOptionsForCell[iIndex][jIndex] = this.getSelectItemAnswerOptionsForCell(iIndex, jIndex);
          var selectedOption = this.selectedOptionsForCell[iIndex][jIndex].find(option => option.value === selectedAnswerOptionId);
          this.selectedAnswerOptionIdList[iIndex][jIndex] = selectedOption ? { label: selectedOption?.label, value: selectedOption?.value} : null;
        } else if (this.columnTypes[jIndex] == ColumnType.Text) {
          const textAnswerOption = answerOption[0].answerOptionCollection[0]
          const questionAnswerInstanceForCell = this.questionAnswerInstanceList.filter(a => a.answerOptionSelectedId === textAnswerOption.id)[0];
          const responseForAnswerOptionIdInCellText = questionAnswerInstanceForCell != null ? questionAnswerInstanceForCell.freeTextAnswer : '';
          this.providedTextListMatrix[iIndex][jIndex] = {
            text: responseForAnswerOptionIdInCellText,
            answerOptionId: textAnswerOption.id
          };
        }
      });
    });
  }

  getSelectItemAnswerOptionsForCell(i, j): SelectItem<number>[] {
    const options = this.questionData.answerCollection
      .filter(a => a.matrixRowId === i + 1 && a.matrixColumnId === j + 1)[0].answerOptionCollection
      .map(a => ({ label: a.text, value: a.id } as SelectItem<number>));
    const defaultOption = { label: '(select)', value: 0 };
    options.unshift(defaultOption);
    return options;
  }

  answerSelected(event, isDropdown) {
    if (event.originalEvent == null && isDropdown) {
      return;
    }

    const selectedOptionRequestList = [];

    this.selectedAnswerOptionIdList.forEach(e1 => {
      e1.filter(x => x.value > 0).forEach(e => {
        selectedOptionRequestList.push({ AnswerOptionId: e?.value, FreeText: null } as SelectedOptionRequest);
      });
    });

    this.providedTextListMatrix.forEach(e1 => {
      e1.filter(x => x.text != null).forEach(e => {
        selectedOptionRequestList.push({ AnswerOptionId: e?.answerOptionId, FreeText: e?.text } as SelectedOptionRequest);
      });
    });

    this.textAnswers.forEach((textAnswer, index) => {
      selectedOptionRequestList
        .push({ AnswerOptionId: textAnswer.answerOptionId, FreeText: textAnswer.freeText } as SelectedOptionRequest);
    });

    if (selectedOptionRequestList.length > 0) {
      this.surveyStateService.saveQuestionAnswersCommand(selectedOptionRequestList,
        this.questionData.id);
    }
  }

  canSave() {
    return this.selectedAnswerOptionIdList.filter(a => a.filter(b => b?.value !== 0).length > 0).length > 0;
  }

  shouldDelete() {
    return this.selectedAnswerOptionIdList.reduce((sum, a) => sum + a.reduce(((sum2, b) => b?.value === 0 ? sum2 + 1 : sum2), 0), 0) === 1;
  }

  ngOnDestroy(): void {
    if (!this.readonly) {
      this.answerSavedSubscription.unsubscribe();
    }
  }
}
