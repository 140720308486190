import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { AccountService } from '../../../../../lib/src/api/services/account.service';
// import { UrlloginService } from '../../../../../lib/src/api/services/urllogin.service';
import { environment } from '../../../environments/environment';
import { Location } from '@angular/common';
import { AccountService } from '../../../../../lib/src/public-api';

@Component({
  selector: 'app-confidentiality-agreement',
  templateUrl: './confidentiality-agreement.component.html',
  styleUrls: ['./confidentiality-agreement.component.scss']
})
export class ConfidentialityAgreementComponent implements OnInit {
  isLegalAccepted = false;
  constructor(
    private router: Router,
    private accountService: AccountService,
    private location: Location) { }

  ngOnInit() {
  }

  homeClicked() {
    this.router.navigate(['/']);
  }
}
