import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { QuestionResponse, QuestionAnswerInstanceResponse, SelectedOptionRequest } from '../../public-api';
import { SurveyStateService } from '../../public-api';
import { QuestionComponent } from '../question.component';


@Component({
  selector: 'app-image-single-select-question',
  templateUrl: './image-single-select-question.component.html',
  styleUrls: ['./image-single-select-question.component.scss']
})
export class ImageSingleSelectQuestionComponent implements OnInit, OnDestroy, QuestionComponent {

  @Input() questionData: QuestionResponse;
  @Input() questionAnswerInstanceList: QuestionAnswerInstanceResponse[];
  @Input() messageService: MessageService;
  @Input() surveyStateService: SurveyStateService;
  @Input() readonly: boolean;
  
  selectedAnswerOptionId;
  showComponent = false;
  private answerSavedSubscription: Subscription;
  constructor() { }

  ngOnInit() {
    this.showComponent = this.surveyStateService.checkVisibilityCondition(this.showComponent, this.questionData.dependsOnAnswerOptionIdCollection, this.questionData.id);
    this.selectedAnswerOptionId = this.questionAnswerInstanceList.map(a => a.answerOptionSelectedId)[0];
    this.answerSavedSubscription = this.surveyStateService.answerSaved.subscribe(
      data => {
        if (data.successStatus) {
          this.showComponent = this.surveyStateService.checkVisibilityCondition(this.showComponent, this.questionData.dependsOnAnswerOptionIdCollection, this.questionData.id);
          if (this.showComponent === false) {
            this.selectedAnswerOptionId = null;
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.answerSavedSubscription.unsubscribe();
  }
  answerSelected(event) {
    this.selectedAnswerOptionId = event;
    this.surveyStateService.saveQuestionAnswersCommand(
      [({ answerOptionId: this.selectedAnswerOptionId, freeText: null } as SelectedOptionRequest)],
      this.questionData.id);
  }
}
