import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GetFilledResponsesPercentageResponse, SurveySectionGetResponse } from '../../../../../lib/src/public-api';

// export interface AddClientsSurveyDialogData {
//   clientName: string;
// }

@Component({
  templateUrl: './add-clients-survey-dialog.component.html',
  styleUrls: ['./add-clients-survey-dialog.component.scss']
})
export class AddClientsSurveyDialogComponent {

  public clientName: string;

  constructor(
    public dialogRef: MatDialogRef<AddClientsSurveyDialogComponent>,
    public translate: TranslateService) {}
}
