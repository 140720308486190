import { Component, OnInit } from '@angular/core';
import { ProgressSpinnerService } from '../../services/progress-spinner.service';

@Component({
  selector: 'app-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss']
})
export class ProgressSpinnerComponent implements OnInit {

  constructor(private progressSpinnerService: ProgressSpinnerService) { }
  visible = false;
  ngOnInit() {
    this.progressSpinnerService.state$.subscribe(a => {
      console.log(`Set spinner values to ${a}`)
      this.visible = a;
    });
  }
}
