<div class="container-fluid dialog-body">
    <mat-dialog-content><span class="message">{{ 'Please provide client name' }}</span></mat-dialog-content>

    <input id="email" type="text" pInputText [(ngModel)]="clientName" style="margin-left: 10px; width: 600px">

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end end">
      <button mat-raised-button color="alert" (click)="dialogRef.close(null)">{{ 'Cancel' | translate}}</button>
        <button mat-raised-button color="alert" (click)="dialogRef.close(clientName)" [disabled]="!clientName || clientName.length == 0">{{ 'Create' | translate}}</button>
    </mat-dialog-actions>
</div>
