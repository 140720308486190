import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-users-panel-container',
  templateUrl: './users-panel-container.component.html',
  styleUrls: ['./users-panel-container.component.scss']
})
export class UsersPanelContainerComponent {

  survey2024Id = environment.Competition2024SurveyId;
  survey2025Id = environment.Competition2025SurveyId;

  constructor() {

    console.log('UsersPanelContainerComponent constructor called');
  }

}
