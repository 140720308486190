<div class="questionWrapper" *ngIf="showComponent" style="margin-bottom: 50px">
  <span class='questionHeader'>
    <span class='questionNumberText' [innerHTML]="questionData.numberText"></span>
    <span class='questionText' [innerHTML]="questionData.text"></span>
  </span>
  <div class="p-grid answersGrid" fxLayout="row" fxLayoutAlign="space-around stretch">
    <p-card
      class="p-col-12 m-1 card"
      *ngFor="let answer of questionData.answerCollection; let i = index"
      [ngClass]="{
        'selectedOptionWithText': answer.answerOptionCollection[0].id === selectedAnswerOptionId,
        'optionWithText': answer.answerOptionCollection[0].id !== selectedAnswerOptionId,
        'hover-enabled': !readonly
      }"
      (click)="labelClicked(answer.answerOptionCollection[0].id)">
      <ng-template pTemplate="title"> {{ 'Stage' | translate }} {{ i + 1 }} </ng-template>
      <div id="answerBox" style="margin-bottom: 10px;">
        <label class='answerLabel'
          [innerHTML]='answer.answerOptionCollection[0].text'></label>
      </div>
    </p-card>
  </div>
  <span class="questionFooter" [innerHTML]="questionData.footerText"></span>
</div>
