import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatListModule } from '@angular/material/list';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { SpinnerModule } from 'primeng/spinner';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
// import { ProgressSpinnerComponent } from '../../projects/common/components/progress-spinner/progress-spinner.component';
import { SidebarModule } from 'primeng/sidebar';
import { MenuModule } from 'primeng/menu';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ToastModule } from 'primeng/toast';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TooltipModule } from 'primeng/tooltip';
// import { ErrorInterceptor } from '../../projects/common/auth/error.interceptor';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
// import { SurveyComponent } from './surveys/survey/survey.component';
// import { SurveyHeaderComponent } from './surveys/survey-header/survey-header.component';
// import { SurveyContentComponent } from './surveys/survey-content/survey-content.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ProgressBarModule } from 'primeng/progressbar';
import { CheckboxModule } from 'primeng/checkbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { DividerModule } from 'primeng/divider';
import { FileUploadModule } from 'primeng/fileupload';
import { PasswordModule } from 'primeng/password';
// import { QuestionListDirective } from '../../projects/common/questions/question-list.directive';
import { MultiSelectModule } from 'primeng/multiselect';
import { ListboxModule } from 'primeng/listbox';
import { UnauthorisedComponent } from './users/unauthorised/unauthorised.component';
// import { ErrorComponent } from './surveys/error/error/error.component';
import { StartPageComponent } from './static/start-page/start-page.component';
// import { SurveyNavigationComponent } from './surveys/survey-navigation/survey-navigation.component';
import { CacheInterceptor } from './ie/cache.interceptor';
import { Competition2024Component } from './static/competition-2024/competition-2024.component';
import { Competition2025Component } from './static/competition-2025/competition-2025.component';
import { LegalComponent } from './static/legal/legal.component';
import { RegistrationComponent } from './users/registration/registration.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { InputTextModule } from 'primeng/inputtext';
import { CardModule } from 'primeng/card';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { LoginComponent } from './users/login/login.component';
// import { JwtInterceptor } from '../../projects/common/interceptors/jwt.interceptor';
import { ChangePasswordComponent } from './users/change-password/change-password.component';
import { SetNewPasswordComponent } from './users/set-new-password/set-new-password.component';
// import { SurveySummaryContentComponent } from './surveys/survey-summary-content/survey-summary-content.component';
// import { EndOfSurveyComponent } from './surveys/end-of-survey/end-of-survey.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ImprintComponent } from './static/imprint/imprint.component';
import { ConfidentialityAgreementComponent } from './static/confidentiality-agreement/confidentiality-agreement.component';
import { PrivacyPolicyComponent } from './static/privacy-policy/privacy-policy.component';
import { DataProtectionComponent } from './static/data-protection/data-protection.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { LandingPageComponent } from './static/landing-page/landing-page.component';
import { UtcToLocalPipe } from '../../../lib/src/pipes/utc-to-local-pipe';
import { environment } from '../environments/environment';
// import { ApiModule } from '../../../../../lib/src/api/api/api.module';
// import { ConfirmDialogComponent } from '../../../../../lib/src/api/components/dialogs/confirm-dialog/confirm-dialog.component';
// import { InformationDialogComponent } from '../../../../../lib/src/api/components/dialogs/information-dialog/information-dialog.component';
import {
  AngularMaterialModule,
  ApiModule,
  ConfirmDialogComponent,
  DividerComponent,
  EndOfSurveyComponent,
  ErrorComponent,
  // FOYCommonModule,
  InformationDialogComponent,
  ProgressSpinnerComponent,
  QuestionListDirective,
  SurveyComponent,
  SurveyContentComponent,
  SurveyHeaderComponent,
  SurveyNavigationComponent,
  SurveySummaryContentComponent
} from '../../../lib/src/public-api'
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { UsersPanelComponent } from './admin/users-panel/users-panel.component';
import { PercentageDialogComponent } from './admin/percentage-dialog/percentage-dialog.component';
import { UsersPanelContainerComponent } from './admin/users-panel-container/users-panel-container.component';
import { ImageSingleSelectQuestionComponent } from '../../../lib/src/questions/image-single-select-question/image-single-select-question.component';
import { CategoryMultiSelectQuestionComponent } from '../../../lib/src/questions/category-multi-select-question/category-multi-select-question.component';
import { FreeTextQuestionComponent } from '../../../lib/src/questions/free-text-question/free-text-question.component';
import { MatrixDropdownQuestionComponent } from '../../../lib/src/questions/matrix-dropdown-question/matrix-dropdown-question.component';
import { MultiSelectQuestionComponent } from '../../../lib/src/questions/multi-select-question/multi-select-question.component';
import { MultiSelectSeriesQuestionComponent } from '../../../lib/src/questions/multi-select-series-question/multi-select-series-question.component';
import { SingleSelectSeriesQuestionComponent } from '../../../lib/src/questions/single-select-series-question/single-select-series-question.component';
import { CategoryMultiSelectOrderedQuestionComponent } from '../../../lib/src/questions/category-multi-select-ordered-question/category-multi-select-ordered-question.component';
import { EmailQuestionComponent } from '../../../lib/src/questions/email-question/email-question.component';
import { FileUploadQuestionComponent } from '../../../lib/src/questions/file-upload-question/file-upload-question.component';
import { FreeTextNumericalQuestionComponent } from '../../../lib/src/questions/free-text-numerical-question/free-text-numerical-question.component';
import { MatrixAutocompleteQuestionComponent } from '../../../lib/src/questions/matrix-autocomplete-question/matrix-autocomplete-question.component';
import { MatrixMixedQuestionComponent } from '../../../lib/src/questions/matrix-mixed-question/matrix-mixed-question.component';
import { MatrixNumericalQuestionComponent } from '../../../lib/src/questions/matrix-numerical-question/matrix-numerical-question.component';
import { MatrixTextQuestionComponent } from '../../../lib/src/questions/matrix-text-question/matrix-text-question.component';
import { MultiLevelComponent } from '../../../lib/src/questions/multi-level/multi-level.component';
import { SingleSelectQuestionComponent } from '../../../lib/src/questions/single-select-question/single-select-question.component';
import { TextAreaQuestionComponent } from '../../../lib/src/questions/text-area-question/text-area-question.component';
import { TextQuestionComponent } from '../../../lib/src/questions/text-question/text-question.component';
import { AddClientsSurveyDialogComponent } from '../../../admin/src/app/components/add-clients-survey-dialog/add-clients-survey-dialog.component';
import { PhaseQuestionComponent } from '../../../lib/src/questions/phase-question/phase-question.component';

// 'projects/lib/src/public-api';

@NgModule({
  declarations: [
    AppComponent,
    ProgressSpinnerComponent,
    SurveyComponent,
    SurveyHeaderComponent,
    SurveyContentComponent,
    QuestionListDirective,
    UnauthorisedComponent,
    ErrorComponent,
    StartPageComponent,
    SurveyNavigationComponent,
    Competition2024Component,
    Competition2025Component,
    LegalComponent,
    RegistrationComponent,
    LoginComponent,
    ChangePasswordComponent,
    SetNewPasswordComponent,
    SurveySummaryContentComponent,
    EndOfSurveyComponent,
    ImprintComponent,
    ConfidentialityAgreementComponent,
    PrivacyPolicyComponent,
    DataProtectionComponent,
    ConfirmDialogComponent,
    LandingPageComponent,
    InformationDialogComponent,
    UtcToLocalPipe,
    UsersPanelComponent,
    PercentageDialogComponent,
    UsersPanelContainerComponent,
    DividerComponent,
    // Questions

    MultiSelectQuestionComponent,
    MultiSelectSeriesQuestionComponent,
    SingleSelectSeriesQuestionComponent,
    FreeTextQuestionComponent,
    CategoryMultiSelectQuestionComponent,
    ImageSingleSelectQuestionComponent,
    MatrixDropdownQuestionComponent,
    CategoryMultiSelectOrderedQuestionComponent,
    EmailQuestionComponent,
    MatrixAutocompleteQuestionComponent,
    MultiLevelComponent,
    SingleSelectQuestionComponent,
    TextAreaQuestionComponent,
    EmailQuestionComponent,
    MatrixAutocompleteQuestionComponent,
    MultiLevelComponent,
    SingleSelectQuestionComponent,
    TextAreaQuestionComponent,
    FileUploadQuestionComponent,
    FreeTextNumericalQuestionComponent,
    MatrixNumericalQuestionComponent,
    EmailQuestionComponent,
    MatrixAutocompleteQuestionComponent,
    MultiLevelComponent,
    MatrixMixedQuestionComponent,
    MatrixTextQuestionComponent,
    TextQuestionComponent,
    SingleSelectQuestionComponent,
    TextQuestionComponent,
    AddClientsSurveyDialogComponent,
    PhaseQuestionComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ButtonModule,
    CalendarModule,
    TableModule,
    DropdownModule,
    SpinnerModule,
    HttpClientModule,
    SidebarModule,
    MenuModule,
    ScrollPanelModule,
    ToastModule,
    ProgressSpinnerModule,
    ConfirmDialogModule,
    TooltipModule,
    OverlayPanelModule,
    AutoCompleteModule,
    RadioButtonModule,
    ProgressBarModule,
    CheckboxModule,
    InputNumberModule,
    DividerModule,
    InputTextModule,
    CardModule,
    MultiSelectModule,
    ListboxModule,
    NgApexchartsModule,
    MatMenuModule,
    MatIconModule,
    MatSidenavModule,
    MatToolbarModule,
    AngularMaterialModule,
    MatButtonModule,
    FileUploadModule,
    PasswordModule,
    MatListModule,
    MessagesModule,
    MessageModule,
    MatProgressBarModule,
    InputTextareaModule,
    CommonModule,
    ApiModule.forRoot({ rootUrl: environment.apiURL }),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })
  ],
  providers: [
    MessageService,
    ConfirmationService,
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  // exports: [
  //   UtcToLocalPipe
  // ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    registerLocaleData(localeDe, 'de-DE', localeDeExtra);
  }
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
