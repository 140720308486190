import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, throwError } from 'rxjs';
import { AccountService } from '../../../../../lib/src/public-api';
import { AddClientsSurveyDialogComponent } from '../../../../../admin/src/app/components/add-clients-survey-dialog/add-clients-survey-dialog.component';
import { MatDialog } from '@angular/material/dialog';

export interface SurveyData {
  id: number;
  name: string;
}

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  email = '';
  pin = '';
  isLegalAccepted = false;
  legalIsBlocking = false;
  // isReadOnlyModeEnabled = environment.readOnlyModeEnabledForSurveyIdList.indexOf(environment.longSurveyId) >= 0;

  surveys: SurveyData[] = [
    {
      id: 1,
      name: 'Client name 1'
    },
    {
      id: 2,
      name: 'Client name 2'
    }
  ];

  token = '';
  stopSubscription$ = new Subject();

  isKearneyUser = true;
  constructor(
    private accountService: AccountService,
    private router: Router,
    private dialog: MatDialog,
    ) { }

  ngOnInit() {
    // if (this.accountService.getUser().surveyId !== environment.longSurveyId) {
    //   this.router.navigate(['/unauthorized']);
    // }
  }

  redirectToExternalURL(url: string): void {
    window.location.href = url;
  }

  addClientSurvey() {
    const dialogRef = this.dialog.open(AddClientsSurveyDialogComponent);
  }
}
