import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { QuestionResponse, SurveyStateService } from '../../public-api';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { QuestionAnswerInstanceResponse } from'../../public-api';
import { QuestionComponent } from '../question.component';

@Component({
  selector: 'app-divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.scss']
})
export class DividerComponent implements QuestionComponent {

  @Input() questionData: QuestionResponse;
  @Input() questionAnswerInstanceList: QuestionAnswerInstanceResponse[];
  @Input() messageService: MessageService;
  @Input() surveyStateService: SurveyStateService;
  @Input() readonly: boolean;
}
