import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { AccountService } from "../public-api";


@Injectable({ providedIn: 'root' })
export class SurveySubmittedGuard  {
    constructor(
        private router: Router,
        private accountService: AccountService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const url = route.url.map(segment => segment.path).join('/');
        const token = url.split('/').pop().split('?')[0];
        var user = this.accountService.getUser();
        var selectedSurvey = user.surveys.find(s => s.surveyToken === token);

        if (!selectedSurvey.isSurveyFinished) {
          return true;
        }
        this.router.navigate([`/end-of-survey/${selectedSurvey.surveyToken}`]);
        return false;
    }
}
