import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { MessageService, SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { QuestionResponse, QuestionAnswerInstanceResponse, SelectedOptionRequest } from '../../public-api';
import { SurveyStateService } from '../../public-api';
import { QuestionComponent } from '../question.component';

@Component({
  selector: 'app-matrix-autocomplete-question',
  templateUrl: './matrix-autocomplete-question.component.html',
  styleUrls: ['./matrix-autocomplete-question.component.scss']
})
export class MatrixAutocompleteQuestionComponent implements OnInit, OnDestroy, QuestionComponent {
  @Input() questionData: QuestionResponse;
  @Input() questionAnswerInstanceList: QuestionAnswerInstanceResponse[];
  @Input() messageService: MessageService;
  @Input() surveyStateService: SurveyStateService;
  @Input() readonly: boolean;

  selectedAnswerOptionList: SelectItem[][] = [];
  selectedOptionsForCell: SelectItem[][][] = [];
  selectedOptionsForCellSuggestions: SelectItem[][][] = [];
  orderedColumnNames = [];
  orderedRowNames = [];
  showComponent = false;

  private answerSavedSubscription: Subscription;
  constructor() { }

  ngOnInit() {
    this.prepareData();
    this.showComponent = this.surveyStateService.checkVisibilityCondition(this.showComponent, this.questionData.dependsOnAnswerOptionIdCollection, this.questionData.id);
    this.answerSavedSubscription = this.surveyStateService.answerSaved.subscribe(
      data => {
        if (data.successStatus) {
          if (data.successStatus) {
            this.showComponent = this.surveyStateService.checkVisibilityCondition(this.showComponent, this.questionData.dependsOnAnswerOptionIdCollection, this.questionData.id);
            if (this.showComponent === false) {
            }
          }
        }
      });
  }

  prepareData() {
    this.questionData.answerCollection = this.questionData.answerCollection.sort((a, b) =>
      a.matrixRowId - b.matrixRowId || a.matrixColumnId - b.matrixColumnId);
      
    this.orderedColumnNames = this.questionData.matrixColumnConfigurationCollection.sort(colConfig => colConfig.matrixColumnId).map(colConfig => colConfig.matrixColumnText)
    this.orderedRowNames = this.questionData.matrixRowConfigurationCollection.sort(rowConfig => rowConfig.matrixRowId).map(colConfig => colConfig.matrixRowText)
  
    this.orderedRowNames.forEach((rowName, iIndex) => {
      this.selectedAnswerOptionList.push(new Array(this.orderedRowNames.length));
      this.selectedOptionsForCell.push(new Array(this.orderedRowNames.length));
      this.selectedOptionsForCellSuggestions.push(new Array(this.orderedRowNames.length));
      this.orderedColumnNames.forEach((colName, jIndex) => {
        const answerOptionIdsInCell = this.questionData.answerCollection
          .filter(a => a.matrixRowId === iIndex + 1 && a.matrixColumnId === jIndex + 1)[0]
          .answerOptionCollection.map(a => a.id);
        const selectedAnswerOption = answerOptionIdsInCell.filter(a => this.questionAnswerInstanceList.map(b => b.answerOptionSelectedId).includes(a))[0];

        this.selectedOptionsForCell[iIndex][jIndex] = this.getSelectItemAnswerOptionsForCell(iIndex, jIndex);
        this.selectedAnswerOptionList[iIndex][jIndex] =
          this.selectedOptionsForCell[iIndex][jIndex].filter(a => a.value === selectedAnswerOption)[0];
      });
    });
  }

  getSelectItemAnswerOptionsForCell(i, j): SelectItem[] {
    const options = this.questionData.answerCollection
      .filter(a => a.matrixRowId === i + 1 && a.matrixColumnId === j + 1)[0].answerOptionCollection
      .map(a => ({ label: a.text, value: a.id } as SelectItem));
    const defaultOption = { label: '', value: 0 };
    options.unshift(defaultOption);
    return options;
  }

  search(event, i, j) {
    this.selectedOptionsForCellSuggestions[i][j] =
      this.selectedOptionsForCell[i][j]
        .filter(a => a.label.toLowerCase().indexOf(event.query.toLowerCase()) === 0);
  }

  answerSelected(event) {
    const selectedOptionRequestList = [];
    if (this.canSave()) {
      this.selectedAnswerOptionList.forEach(e1 => {
        e1.forEach(e => {
          selectedOptionRequestList.push({ AnswerOptionId: e.value, FreeText: null } as SelectedOptionRequest);
        });
      });
      this.surveyStateService.saveQuestionAnswersCommand(selectedOptionRequestList,
        this.questionData.id);
    } else if (this.shouldDelete()) {
      this.surveyStateService.deleteQuestionAnswersCommand(this.questionData.id);
    }
  }

  canSave() {
    return this.selectedAnswerOptionList.filter(a => a.filter(b => b.value === 0).length > 0).length === 0;
  }

  shouldDelete() {
    return this.selectedAnswerOptionList.reduce((sum, a) => sum + a.reduce(((sum2, b) => b.value === 0 ? sum2 + 1 : sum2), 0), 0) === 1;
  }

  ngOnDestroy(): void {
    this.answerSavedSubscription.unsubscribe();
  }
}
