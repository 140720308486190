import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MessageService, SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { QuestionResponse, QuestionAnswerInstanceResponse, SelectedOptionRequest } from '../../public-api';
import { SurveyStateService } from '../../public-api';
import { QuestionComponent } from '../question.component';


@Component({
  selector: 'app-category-multi-select-question',
  templateUrl: './category-multi-select-question.component.html',
  styleUrls: ['./category-multi-select-question.component.scss']
})
export class CategoryMultiSelectQuestionComponent implements OnInit, OnDestroy, QuestionComponent {

  @Input() questionData: QuestionResponse;
  @Input() questionAnswerInstanceList: QuestionAnswerInstanceResponse[];
  @Input() messageService: MessageService;
  @Input() surveyStateService: SurveyStateService;
  @Input() readonly: boolean;
  
  categoryList: SelectItem[];
  selectedCategoryAnswerOptionIdList;
  showComponent = false;
  private answerSavedSubscription: Subscription;
  constructor() { }

  ngOnInit() {
    this.showComponent = this.surveyStateService.checkVisibilityCondition(this.showComponent, this.questionData.dependsOnAnswerOptionIdCollection, this.questionData.id);
    this.categoryList = this.questionData.answerCollection[0].answerOptionCollection.map(a => ({ label: a.text, value: a.id } as SelectItem));
    this.selectedCategoryAnswerOptionIdList = this.questionAnswerInstanceList.map(a => a.answerOptionSelectedId);
    this.answerSavedSubscription = this.surveyStateService.answerSaved.subscribe(
      data => {
        if (data.successStatus) {
          this.showComponent = this.surveyStateService.checkVisibilityCondition(this.showComponent, this.questionData.dependsOnAnswerOptionIdCollection, this.questionData.id);
          if (this.showComponent === false) {
            this.selectedCategoryAnswerOptionIdList = [];
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.answerSavedSubscription.unsubscribe();
  }
  answerSelected(event) {
    this.surveyStateService.saveQuestionAnswersCommand(
      this.selectedCategoryAnswerOptionIdList.map(a => ({ AnswerOptionId: a, FreeText: null } as SelectedOptionRequest)),
      this.questionData.id);
  }
}
