<div class="p-grid grid">
  <div class="p-col-12 p-lg-6" style="margin: 0 auto;margin-bottom: 30px;">
    <div class="p-grid">
      <div class="p-col-12 welcomeTitle">
        {{ 'Factory of the Year - 2025 Competition' | translate }}
      </div>
      <!-- <div fxLayout="row" fxLayoutAlign="space-around center" >
        <div >
          <img src="./assets/Kitot.png"  class="image" style="width: 150px; margin-top: 50px"/>
        </div>
      </div>
      <div class="p-col-12">
        <img src="./assets/kearney.png" width="340px" />
      </div>
      <div class="p-col-12">
        <p class="welcomeText1" [innerHTML]="'Welcome' | translate"> w
        </p>
        <img src="../../../assets/benchmark_example.PNG"  class = "image-benchmark" />
      </div> -->
      <div fxLayout="row" fxLayoutAlign="space-evenly center" class="button-container">
        <div class="p-col-12" style="margin-bottom: 50px">
          <div class="nextButtonWrapper">
            <button pButton class="button" label="{{ 'Introduction' | translate }}" [routerLink]="['/legal']"></button>
          </div>
        </div>

        <div class="p-col-12" style="margin-bottom: 50px">
          <div class="nextButtonWrapper">
            <button pButton class="button" label="{{ 'Questionnaire' | translate }}" (click)="buttonStartClicked()"></button>
          </div>
        </div>
      </div>
      <div style="text-align: left;" innerHTML="{{ 'Intro text' | translate }}">
    </div>
  </div>
</div>
