import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { QuestionComponent } from '../question.component';
import { QuestionResponse, QuestionAnswerInstanceResponse } from '../../public-api';
import { SurveyStateService } from '../../public-api';

@Component({
  selector: 'app-email-question',
  templateUrl: './email-question.component.html',
  styleUrls: ['./email-question.component.scss']
})
export class EmailQuestionComponent implements OnInit, OnDestroy, QuestionComponent {

  @Input() questionData: QuestionResponse;
  @Input() questionAnswerInstanceList: QuestionAnswerInstanceResponse[];
  @Input() messageService: MessageService;
  @Input() surveyStateService: SurveyStateService;
  @Input() readonly: boolean;

  answerText: string;
  selectedAnswerOptionId;
  showComponent = false;
  private answerSavedSubscription: Subscription;
  constructor(private translate: TranslateService) { }

  ngOnInit() {
    this.showComponent = this.surveyStateService?.checkVisibilityCondition(this.showComponent, this.questionData.dependsOnAnswerOptionIdCollection, this.questionData.id) ?? true;
    this.answerText = this.questionAnswerInstanceList[0] ? this.questionAnswerInstanceList[0].freeTextAnswer : '';
    this.selectedAnswerOptionId = this.questionData.answerCollection[0].answerOptionCollection[0].id;

    if (!this.readonly) {
      this.answerSavedSubscription = this.surveyStateService.answerSaved.subscribe(
        data => {
          if (data.successStatus) {
            this.showComponent = this.surveyStateService.checkVisibilityCondition(this.showComponent, this.questionData.dependsOnAnswerOptionIdCollection, this.questionData.id);
            if (this.showComponent === false) {
              this.answerText = null;
            }
          }
        });
    }
  }

  ngOnDestroy(): void {
    if (!this.readonly) {
      this.answerSavedSubscription.unsubscribe();
    }
  }

  isTextProvided() {
    return this.answerText !== null && this.answerText.length > 0;
  }

  isProperEmailProvided() {
    const emailRegex = /\S+@\S+\.\S+/;
    return this.answerText !== null && this.answerText.length > 0 && emailRegex.test(this.answerText);
  }

  answerSelected(event) {
    let selectedAnswerOptionIdList = [];
    if (this.isProperEmailProvided()) {
      selectedAnswerOptionIdList = [{ AnswerOptionId: this.selectedAnswerOptionId, FreeText: this.answerText }];
    } else if (this.isTextProvided()) {
      this.showInvalidEmialError();
    }
    this.surveyStateService.saveQuestionAnswersCommand(
      selectedAnswerOptionIdList,
      this.questionData.id);
  }

  showInvalidEmialError() {
    this.messageService.clear();
    this.messageService.add({ severity: 'error', summary: 'Info', detail: this.translate.instant('Please provide proper email address'), sticky: false, life: 4000 });
  }
}
