import { QuestionAnswerGetResponse } from "../public-api";
import { CategoryMultiSelectOrderedQuestionComponent } from "./category-multi-select-ordered-question/category-multi-select-ordered-question.component";
import { CategoryMultiSelectQuestionComponent } from "./category-multi-select-question/category-multi-select-question.component";
import { DividerComponent } from "./divider/divider.component";
import { EmailQuestionComponent } from "./email-question/email-question.component";
import { FileUploadQuestionComponent } from "./file-upload-question/file-upload-question.component";
import { FreeTextNumericalQuestionComponent } from "./free-text-numerical-question/free-text-numerical-question.component";
import { FreeTextQuestionComponent } from "./free-text-question/free-text-question.component";
import { ImageSingleSelectQuestionComponent } from "./image-single-select-question/image-single-select-question.component";
import { MatrixAutocompleteQuestionComponent } from "./matrix-autocomplete-question/matrix-autocomplete-question.component";
import { MatrixDropdownQuestionComponent } from "./matrix-dropdown-question/matrix-dropdown-question.component";
import { MatrixMixedQuestionComponent } from "./matrix-mixed-question/matrix-mixed-question.component";
import { MatrixNumericalQuestionComponent } from "./matrix-numerical-question/matrix-numerical-question.component";
import { MatrixTextQuestionComponent } from "./matrix-text-question/matrix-text-question.component";
import { MultiLevelComponent } from "./multi-level/multi-level.component";
import { MultiSelectQuestionComponent } from "./multi-select-question/multi-select-question.component";
import { MultiSelectSeriesQuestionComponent } from "./multi-select-series-question/multi-select-series-question.component";
import { PhaseQuestionComponent } from "./phase-question/phase-question.component";
import { QuestionItem } from "./question-item";
import { SingleSelectQuestionComponent } from "./single-select-question/single-select-question.component";
import { SingleSelectSeriesQuestionComponent } from "./single-select-series-question/single-select-series-question.component";
import { TextAreaQuestionComponent } from "./text-area-question/text-area-question.component";
import { TextQuestionComponent } from "./text-question/text-question.component";

function evaluateType(name: string) {
  if (name.indexOf('MapCountrySelection') > -1) {
    return 'MapCountrySelection';
  } else if (name.indexOf('RegionBasedCountrySelection') > -1) {
    return 'RegionBasedCountrySelection';
  } else {
    return name;
  }
}

export function getQuestions(pageData: QuestionAnswerGetResponse) {
  const questionItems: QuestionItem[] = [];

  var surveySubSectionId = -1;

  pageData.questionListInGroup.sort((a, b) => {
    // Compare by param1 first
    if (a.surveySectionId == 97) {
      return a.orderNumber - b.orderNumber;
    }

    if (a.surveySectionId !== b.surveySectionId) {
      return a.surveySectionId - b.surveySectionId;
    }

    // If param1 is equal, compare by param2
    if (a.surveySubSectionId < b.surveySubSectionId) {
      return -1;
    } else if (a.surveySubSectionId > b.surveySubSectionId) {
      return 1;
    } else {
      return 0;
    }
  });

  pageData.questionListInGroup.forEach(q => {
    const qa = pageData.questionAnswerInstanceListInGroup.filter(a => a.questionId === q.id);

    if (surveySubSectionId != q.surveySubSectionId) {
      questionItems.push(new QuestionItem(DividerComponent, q, qa));
      surveySubSectionId = q.surveySubSectionId;
    }

    switch (evaluateType(q.questionType.name)) {
      case 'SingleSelect': {
        questionItems.push(new QuestionItem(SingleSelectQuestionComponent, q, qa));
        break;
      }
      case 'ParticipationConsent': {
        questionItems.push(new QuestionItem(SingleSelectQuestionComponent, q, qa));
        break;
      }
      case 'MultiSelect': {
        questionItems.push(new QuestionItem(MultiSelectQuestionComponent, q, qa));
        break;
      }
      case 'MultiSelectMax3Answers': {
        questionItems.push(new QuestionItem(MultiSelectQuestionComponent, q, qa));
        break;
      }
      case 'SingleSelectSeries': {
        questionItems.push(new QuestionItem(SingleSelectSeriesQuestionComponent, q, qa));
        break;
      }
      case 'MultiSelectSeries': {
        questionItems.push(new QuestionItem(MultiSelectSeriesQuestionComponent, q, qa));
        break;
      }
      case 'FreeText': {
        questionItems.push(new QuestionItem(FreeTextQuestionComponent, q, qa));
        break;
      }
      case 'CategoryMultiSelect': {
        questionItems.push(new QuestionItem(CategoryMultiSelectQuestionComponent, q, qa));
        break;
      }
      case 'ImageSingleSelect': {
        questionItems.push(new QuestionItem(ImageSingleSelectQuestionComponent, q, qa));
        break;
      }
      case 'FreeTextNumerical': {
        questionItems.push(new QuestionItem(FreeTextNumericalQuestionComponent, q, qa));
        break;
      }
      case 'MatrixDropdown': {
        questionItems.push(new QuestionItem(MatrixDropdownQuestionComponent, q, qa));
        break;
      }
      case 'MatrixNumerical': {
        questionItems.push(new QuestionItem(MatrixNumericalQuestionComponent, q, qa));
        break;
      }
      // case 'MapCountrySelection': {
      //   questionItems.push(new QuestionItem(MapCountrySelectionQuestionComponent, q, qa));
      //   break;
      // }
      case 'CategoryMultiSelectOrdered': {
        questionItems.push(new QuestionItem(CategoryMultiSelectOrderedQuestionComponent, q, qa));
        break;
      }
      // case 'RegionBasedCountrySelection': {
      //   questionItems.push(new QuestionItem(RegionBasedCountrySelectionQuestionComponent, q, qa));
      //   break;
      // }
      // case 'RegionBasedMaturityLevelSelection': {
      //   questionItems.push(new QuestionItem(RegionBasedMaturityLevelSelectionQuestionComponent, q, qa));
      //   break;
      // }
      // case 'RegionBasedCountryExpandSelection': {
      //   questionItems.push(new QuestionItem(RegionBasedCountryExpandSelectionQuestionComponent, q, qa));
      //   break;
      // }
      case 'DiverseSuppliersCountFreeTextNumerical': {
        questionItems.push(new QuestionItem(FreeTextNumericalQuestionComponent, q, qa));
        break;
      }
      case 'DiverseSuppliersCountLessThanTextNumerical': {
        questionItems.push(new QuestionItem(FreeTextNumericalQuestionComponent, q, qa));
        break;
      }
      case 'DiverseSuppliersSpendFreeTextNumerical': {
        questionItems.push(new QuestionItem(FreeTextNumericalQuestionComponent, q, qa));
        break;
      }
      case 'DiverseSuppliersSpendLessThanFreeTextNumerical': {
        questionItems.push(new QuestionItem(FreeTextNumericalQuestionComponent, q, qa));
        break;
      }
      case 'UserName': {
        questionItems.push(new QuestionItem(FreeTextQuestionComponent, q, qa));
        break;
      }
      case 'Email': {
        questionItems.push(new QuestionItem(EmailQuestionComponent, q, qa));
        break;
      }
      case 'MatrixAutocomplete': {
        questionItems.push(new QuestionItem(MatrixAutocompleteQuestionComponent, q, qa));
        break;
      }
      case 'Levels': {
        questionItems.push(new QuestionItem(MultiLevelComponent, q, qa));
        break;
      }
      case 'TextArea': {
        questionItems.push(new QuestionItem(TextAreaQuestionComponent, q, qa));
        break;
      }
      case 'FileUpload': {
        questionItems.push(new QuestionItem(FileUploadQuestionComponent, q, qa));
        break;
      }
      case 'MixedMatrix': {
        questionItems.push(new QuestionItem(MatrixMixedQuestionComponent, q, qa));
        break;
      }
      case 'TextMatrix': {
        questionItems.push(new QuestionItem(MatrixTextQuestionComponent, q, qa));
        break;
      }
      case 'Text': {
        questionItems.push(new QuestionItem(TextQuestionComponent, q, qa));
        break;
      }
      case 'Phase': {
        questionItems.push(new QuestionItem(PhaseQuestionComponent, q, qa));
        break;
      }
    }
  });
  return questionItems;
}
