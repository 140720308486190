import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { FileUpload } from 'primeng/fileupload';
import { TranslateService } from '@ngx-translate/core';
import { QuestionResponse, QuestionAnswerInstanceResponse, UploadedFileResponse, FilesService } from '../../public-api';
// import { FilesService } from '../../public-api';
import { SurveyStateService } from '../../public-api';
import { QuestionComponent } from '../question.component';

interface UploadEvent {
  files: File[]
}

interface FileToShow {
  id: number;
  name: string
}

@Component({
  selector: 'app-file-upload-question',
  templateUrl: './file-upload-question.component.html',
  styleUrls: ['./file-upload-question.component.scss']
})
export class FileUploadQuestionComponent implements OnInit, OnDestroy, QuestionComponent {

  @Input() questionData: QuestionResponse;
  @Input() questionAnswerInstanceList: QuestionAnswerInstanceResponse[];
  @Input() messageService: MessageService;
  @Input() surveyStateService: SurveyStateService;
  @Input() readonly: boolean;

  uploadedFiles: FileToShow[] = [];
  tooltip: string;
  
  selectedAnswerOptionId;
  showComponent = false;
  private answerSavedSubscription: Subscription;
  private filesLimiter = "|"
  constructor(private filesService: FilesService,
    private translate: TranslateService) { }

  ngOnInit() {
    this.showComponent = this.surveyStateService?.checkVisibilityCondition(this.showComponent, this.questionData.dependsOnAnswerOptionIdCollection, this.questionData.id) ?? true;
    this.selectedAnswerOptionId = this.questionData.answerCollection[0].answerOptionCollection[0].id;
    this.tooltip = this.questionData.tooltipText;

    if (this.questionAnswerInstanceList.length > 0) {
      this.filesService.apiFilesGet$Json({questionAnswerInstanceId: this.questionAnswerInstanceList[0].id, questionId: this.questionData.id})
        .subscribe(files => {
          for (var file of files) {
            this.uploadedFiles.push({ 
              name: file.originalName,
              id: file.id
            });
         }
        });
    }
  }

  ngOnDestroy(): void {
    // this.answerSavedSubscription.unsubscribe();
  }

  onUpload($event: UploadEvent, fileUpload: FileUpload) {
    var successCallback = (files: UploadedFileResponse[]) => {
      for (let file of files) {
        this.uploadedFiles.push({ name: file.originalName, id: file.id });
      }
    }

    var files = this.uploadedFiles.map(file => file.name).join(this.filesLimiter);
    this.surveyStateService.uploadFiles($event.files, this.selectedAnswerOptionId, files, this.questionData.id, successCallback);
    fileUpload.clear();
  }

  deleteFile(file: UploadedFileResponse) {
    this.filesService.apiFilesDelete$Response({fileId: file.id})
      .subscribe(_ => {
        this.uploadedFiles = this.uploadedFiles.filter(f => f.id != file.id);
        this.messageService.add({ severity: 'info', summary: 'Info', detail: this.translate.instant('File successfully deleted'), sticky: true });
      }, _ => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: this.translate.instant('Error occurred while deleting file'), sticky: true });
      });
  }
}
