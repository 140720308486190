import { Component, ViewEncapsulation } from '@angular/core';
import { AccountService } from '../../services/account.service';

@Component({
  selector: 'app-end-of-survey',
  templateUrl: './end-of-survey.component.html',
  styleUrls: ['./end-of-survey.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EndOfSurveyComponent {
  constructor(private accountService: AccountService) {
    const url = window.location.href;
    const token = url.split('/').pop().split('?')[0];
    var user = this.accountService.getUser();
    var selectedSurvey = user.surveys.find(s => s.surveyToken === token);
    this.accountService.setCurrentSurveyInstance(selectedSurvey.surveyInstanceId);
  }
}
