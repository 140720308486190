/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BenchmarkFilters } from '../models/benchmark-filters';
import { UserSurveyDataResponse } from '../models/user-survey-data-response';

@Injectable({
  providedIn: 'root',
})
export class UsersPanelService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiUsersPanelGet
   */
  static readonly ApiUsersPanelGetPath = '/api/UsersPanel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersPanelGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersPanelGet$Plain$Response(params: {
    surveyId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserSurveyDataResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, UsersPanelService.ApiUsersPanelGetPath, 'get');
    if (params) {
      rb.query('surveyId', params.surveyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserSurveyDataResponse>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUsersPanelGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersPanelGet$Plain(params: {
    surveyId: number;
    context?: HttpContext
  }
): Observable<Array<UserSurveyDataResponse>> {

    return this.apiUsersPanelGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserSurveyDataResponse>>) => r.body as Array<UserSurveyDataResponse>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersPanelGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersPanelGet$Json$Response(params: {
    surveyId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserSurveyDataResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, UsersPanelService.ApiUsersPanelGetPath, 'get');
    if (params) {
      rb.query('surveyId', params.surveyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserSurveyDataResponse>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUsersPanelGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersPanelGet$Json(params: {
    surveyId: number;
    context?: HttpContext
  }
): Observable<Array<UserSurveyDataResponse>> {

    return this.apiUsersPanelGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserSurveyDataResponse>>) => r.body as Array<UserSurveyDataResponse>)
    );
  }

  /**
   * Path part for operation apiUsersPanelPut
   */
  static readonly ApiUsersPanelPutPath = '/api/UsersPanel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersPanelPut()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersPanelPut$Response(params?: {
    userId?: number;
    surveyId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UsersPanelService.ApiUsersPanelPutPath, 'put');
    if (params) {
      rb.query('userId', params.userId, {});
      rb.query('surveyId', params.surveyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUsersPanelPut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersPanelPut(params?: {
    userId?: number;
    surveyId?: number;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiUsersPanelPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiUsersPanelExcelGet
   */
  static readonly ApiUsersPanelExcelGetPath = '/api/UsersPanel/excel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersPanelExcelGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersPanelExcelGet$Response(params: {
    surveyId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, UsersPanelService.ApiUsersPanelExcelGetPath, 'get');
    if (params) {
      rb.query('surveyId', params.surveyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUsersPanelExcelGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersPanelExcelGet(params: {
    surveyId: number;
    context?: HttpContext
  }
): Observable<Blob> {

    return this.apiUsersPanelExcelGet$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation apiUsersPanelGetBenchmarksFiltersGet
   */
  static readonly ApiUsersPanelGetBenchmarksFiltersGetPath = '/api/UsersPanel/get-benchmarks-filters';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersPanelGetBenchmarksFiltersGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersPanelGetBenchmarksFiltersGet$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BenchmarkFilters>> {

    const rb = new RequestBuilder(this.rootUrl, UsersPanelService.ApiUsersPanelGetBenchmarksFiltersGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BenchmarkFilters>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUsersPanelGetBenchmarksFiltersGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersPanelGetBenchmarksFiltersGet$Plain(params?: {
    context?: HttpContext
  }
): Observable<BenchmarkFilters> {

    return this.apiUsersPanelGetBenchmarksFiltersGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BenchmarkFilters>) => r.body as BenchmarkFilters)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersPanelGetBenchmarksFiltersGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersPanelGetBenchmarksFiltersGet$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BenchmarkFilters>> {

    const rb = new RequestBuilder(this.rootUrl, UsersPanelService.ApiUsersPanelGetBenchmarksFiltersGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BenchmarkFilters>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUsersPanelGetBenchmarksFiltersGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersPanelGetBenchmarksFiltersGet$Json(params?: {
    context?: HttpContext
  }
): Observable<BenchmarkFilters> {

    return this.apiUsersPanelGetBenchmarksFiltersGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BenchmarkFilters>) => r.body as BenchmarkFilters)
    );
  }

}
