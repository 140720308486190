import { Injectable } from "@angular/core";
import { FOYData } from "./foy-data";
import { LanguageService } from "./language.service";
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  private registrationForm: any;
  
  getRegistrationFormData() {
    return this.registrationForm;
  }

  setRegistrationFormData(value) {
    this.registrationForm = value;
  }
}