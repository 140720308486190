/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LanguageEnum } from '../models/language-enum';
import { SurveyGetResponse } from '../models/survey-get-response';

@Injectable({
  providedIn: 'root',
})
export class SurveyService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiSurveyGet
   */
  static readonly ApiSurveyGetPath = '/api/Survey';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSurveyGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveyGet$Plain$Response(params: {
    language: LanguageEnum;
    surveyInstanceId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<SurveyGetResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SurveyService.ApiSurveyGetPath, 'get');
    if (params) {
      rb.query('language', params.language, {});
      rb.query('surveyInstanceId', params.surveyInstanceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SurveyGetResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSurveyGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveyGet$Plain(params: {
    language: LanguageEnum;
    surveyInstanceId: number;
    context?: HttpContext
  }
): Observable<SurveyGetResponse> {

    return this.apiSurveyGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SurveyGetResponse>) => r.body as SurveyGetResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSurveyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveyGet$Json$Response(params: {
    language: LanguageEnum;
    surveyInstanceId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<SurveyGetResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SurveyService.ApiSurveyGetPath, 'get');
    if (params) {
      rb.query('language', params.language, {});
      rb.query('surveyInstanceId', params.surveyInstanceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SurveyGetResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSurveyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveyGet$Json(params: {
    language: LanguageEnum;
    surveyInstanceId: number;
    context?: HttpContext
  }
): Observable<SurveyGetResponse> {

    return this.apiSurveyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SurveyGetResponse>) => r.body as SurveyGetResponse)
    );
  }

  /**
   * Path part for operation apiSurveySubmitSurveyPut
   */
  static readonly ApiSurveySubmitSurveyPutPath = '/api/Survey/submit-survey';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSurveySubmitSurveyPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveySubmitSurveyPut$Plain$Response(params: {
    surveyInstanceId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SurveyService.ApiSurveySubmitSurveyPutPath, 'put');
    if (params) {
      rb.query('surveyInstanceId', params.surveyInstanceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSurveySubmitSurveyPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveySubmitSurveyPut$Plain(params: {
    surveyInstanceId: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.apiSurveySubmitSurveyPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSurveySubmitSurveyPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveySubmitSurveyPut$Json$Response(params: {
    surveyInstanceId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SurveyService.ApiSurveySubmitSurveyPutPath, 'put');
    if (params) {
      rb.query('surveyInstanceId', params.surveyInstanceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSurveySubmitSurveyPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveySubmitSurveyPut$Json(params: {
    surveyInstanceId: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.apiSurveySubmitSurveyPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
