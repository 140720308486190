/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LanguageEnum } from '../models/language-enum';

@Injectable({
  providedIn: 'root',
})
export class FoyService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiFoyGet
   */
  static readonly ApiFoyGetPath = '/api/FOY';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFoyGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFoyGet$Plain$Response(params: {
    language?: LanguageEnum;
    surveyInstanceId: number;
    surveyId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, FoyService.ApiFoyGetPath, 'get');
    if (params) {
      rb.query('language', params.language, {});
      rb.query('surveyInstanceId', params.surveyInstanceId, {});
      rb.query('surveyId', params.surveyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFoyGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFoyGet$Plain(params: {
    language?: LanguageEnum;
    surveyInstanceId: number;
    surveyId: number;
    context?: HttpContext
  }
): Observable<string> {

    return this.apiFoyGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFoyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFoyGet$Json$Response(params: {
    language?: LanguageEnum;
    surveyInstanceId: number;
    surveyId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, FoyService.ApiFoyGetPath, 'get');
    if (params) {
      rb.query('language', params.language, {});
      rb.query('surveyInstanceId', params.surveyInstanceId, {});
      rb.query('surveyId', params.surveyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFoyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFoyGet$Json(params: {
    language?: LanguageEnum;
    surveyInstanceId: number;
    surveyId: number;
    context?: HttpContext
  }
): Observable<string> {

    return this.apiFoyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiFoyGetForUserGet
   */
  static readonly ApiFoyGetForUserGetPath = '/api/FOY/get-for-user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFoyGetForUserGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFoyGetForUserGet$Plain$Response(params: {
    language?: LanguageEnum;
    userId?: number;
    surveyInstanceId: number;
    surveyId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, FoyService.ApiFoyGetForUserGetPath, 'get');
    if (params) {
      rb.query('language', params.language, {});
      rb.query('userId', params.userId, {});
      rb.query('surveyInstanceId', params.surveyInstanceId, {});
      rb.query('surveyId', params.surveyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFoyGetForUserGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFoyGetForUserGet$Plain(params: {
    language?: LanguageEnum;
    userId?: number;
    surveyInstanceId: number;
    surveyId: number;
    context?: HttpContext
  }
): Observable<string> {

    return this.apiFoyGetForUserGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFoyGetForUserGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFoyGetForUserGet$Json$Response(params: {
    language?: LanguageEnum;
    userId?: number;
    surveyInstanceId: number;
    surveyId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, FoyService.ApiFoyGetForUserGetPath, 'get');
    if (params) {
      rb.query('language', params.language, {});
      rb.query('userId', params.userId, {});
      rb.query('surveyInstanceId', params.surveyInstanceId, {});
      rb.query('surveyId', params.surveyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFoyGetForUserGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFoyGetForUserGet$Json(params: {
    language?: LanguageEnum;
    userId?: number;
    surveyInstanceId: number;
    surveyId: number;
    context?: HttpContext
  }
): Observable<string> {

    return this.apiFoyGetForUserGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
