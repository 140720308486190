import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MessageService, SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { QuestionResponse, QuestionAnswerInstanceResponse, SelectedOptionRequest } from '../../public-api';
import { SurveyStateService } from '../../public-api';
import { QuestionComponent } from '../question.component';

@Component({
  selector: 'app-category-multi-select-ordered-question',
  templateUrl: './category-multi-select-ordered-question.component.html',
  styleUrls: ['./category-multi-select-ordered-question.component.scss']
})
export class CategoryMultiSelectOrderedQuestionComponent implements OnInit, OnDestroy, QuestionComponent {

  @Input() questionData: QuestionResponse;
  @Input() questionAnswerInstanceList: QuestionAnswerInstanceResponse[];
  @Input() messageService: MessageService;
  @Input() surveyStateService: SurveyStateService;
  @Input() readonly: boolean;

  selectedAnswerOptionIdList: { label: string, value: number };
  selectedOptionsForCell: SelectItem[] = [];

  fullCategoryList: SelectItem[] = [];
  selectedCategoryAnswerOptionList: QuestionAnswerInstanceResponse[];
  categoriesList: SelectItem[][] = [];
  showComponent = false;

  private isValueSet = false;
  private sortOptionsThreshold = 15;
  private answerSavedSubscription: Subscription;
  constructor() { }

  ngOnInit() {
    this.showComponent = this.surveyStateService?.checkVisibilityCondition(this.showComponent, this.questionData.dependsOnAnswerOptionIdCollection, this.questionData.id) ?? true;

    var answer = this.questionData.answerCollection[0];
    this.selectedOptionsForCell = this.fullCategoryList.concat(answer.answerOptionCollection.filter(ao => ao.text).map(a => ({ label: a.text, value: a.id } as SelectItem)));

    if (this.selectedOptionsForCell.length > this.sortOptionsThreshold) {
      this.selectedOptionsForCell = this.selectedOptionsForCell.sort((a, b) => a.label.localeCompare(b.label));
    }
    // this.selectedOptionsForCell.unshift({ label: 'Choose', value: 0 });

    const answerOptionIdsInCell = answer.answerOptionCollection.map(a => a.id);

    if (answer && answer.answerOptionCollection && answer.answerOptionCollection.length > 0) {
      var selectedAnswerOptionId = answerOptionIdsInCell.filter(a => this.questionAnswerInstanceList.map(b => b.answerOptionSelectedId).includes(a))[0];
      selectedAnswerOptionId = selectedAnswerOptionId ? selectedAnswerOptionId : 0
      var selectedOption = this.selectedOptionsForCell.find(option => option.value === selectedAnswerOptionId);
      this.selectedAnswerOptionIdList = selectedOption ? { label: selectedOption?.label, value: selectedOption?.value} : null;
      this.isValueSet = true;
    }
    // this.selectedCategoryAnswerOptionList = this.questionAnswerInstanceList;
    // if (this.selectedCategoryAnswerOptionList.length === 0) {
    //   this.selectedCategoryAnswerOptionList.push({ answerOptionSelectedId: 0 });
    // }
    // this.selectedCategoryAnswerOptionList.forEach((element, index) => {
    //   this.categoriesList[index] = this.notUsedCategoryList(element.answerOptionSelectedId);
    // });

    if (!this.readonly) {
      this.answerSavedSubscription = this.surveyStateService.answerSaved.subscribe(
        data => {
          if (data.successStatus) {
            this.showComponent = this.surveyStateService.checkVisibilityCondition(this.showComponent, this.questionData.dependsOnAnswerOptionIdCollection, this.questionData.id);
            if (this.showComponent === false) {
              this.selectedCategoryAnswerOptionList = [];
              this.selectedCategoryAnswerOptionList.push({ answerOptionSelectedId: 0 });
            }
          }
        });
    }
  }

  ngOnDestroy(): void {
    if (!this.readonly) {
      this.answerSavedSubscription.unsubscribe();
    }
  }

  answerSelected(event) {
    if (!this.isValueSet || event.originalEvent == null) {
      return;
    }
    if (this.selectedAnswerOptionIdList.value > 0) {
      this.surveyStateService.saveQuestionAnswersCommand(
        [{ AnswerOptionId: this.selectedAnswerOptionIdList.value, FreeText: null } as SelectedOptionRequest],
        this.questionData.id);
    }
  }

  canSave() {
    return this.selectedCategoryAnswerOptionList.some(a => a.answerOptionSelectedId !== 0);
  }

  intToOrdinalNumberString = (num: number): string => {
    num = Math.round(num);
    const numString = num.toString();

    if (Math.floor(num / 10) % 10 === 1) {
      return numString + 'th';
    }
    switch (num % 10) {
      case 1: return numString + 'st';
      case 2: return numString + 'nd';
      case 3: return numString + 'rd';
      default: return numString + 'th';
    }
  }
}
