import { Component, Input } from '@angular/core';
import { MessageService } from 'primeng/api';
// import { AnswerResponse, AnswerOptionResponse, QuestionResponse, QuestionAnswerInstanceResponse } from '../../public-api';
// import { SurveyStateService } from '../../public-api';
import { QuestionComponent } from '../question.component';
import { AnswerOptionResponse, AnswerResponse, QuestionAnswerInstanceResponse, QuestionResponse, SurveyStateService } from '../../public-api';
// import { SurveyStateService } from '../../public-api';
// import { SurveyStateService } from '../../services/surveyState.service';
// import { SurveyStateService } from '../../public-api';
// import { AnswerOptionResponse } from 'projects/lib/src/api/models';

interface ColumnSumValidator {
  greaterThan: number;
  lessThan: number;
  equals: number;
}

interface CellAnswer {
  answer: AnswerResponse,
  answerOption: AnswerOptionResponse
}

@Component({
  selector: 'app-text-question',
  templateUrl: './text-question.component.html',
  styleUrls: ['./text-question.component.scss']
})
export class TextQuestionComponent implements QuestionComponent {
  @Input() readonly: boolean;
  @Input() questionData: QuestionResponse;
  @Input() questionAnswerInstanceList: QuestionAnswerInstanceResponse[];
  @Input() messageService: MessageService;
  @Input() surveyStateService: SurveyStateService;
}
