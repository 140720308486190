import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Location } from '@angular/common';
import { AccountService } from '../../../../../lib/src/public-api';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss']
})
export class ImprintComponent implements OnInit {
  isLegalAccepted = false;
  constructor(
    private router: Router,
    private accountService: AccountService,
    private location: Location) { }

  ngOnInit() {
  }

  homeClicked() {
    this.router.navigate(['/']);
  }
}
