import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-start-page1',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.scss']
})
export class StartPageComponent implements OnInit {
  isLegalAccepted = false;
  constructor(
    private router: Router,
    private location: Location) { }

  ngOnInit() {
  }

  buttonRegisterClicked() {
    this.router.navigate(['/registration']);
  }

  backClicked() {
    this.location.back()
  }
}
