import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { QuestionResponse, QuestionAnswerInstanceResponse, SelectedOptionRequest } from '../../public-api';
import { SurveyStateService } from '../../public-api';
import { QuestionComponent } from '../question.component';

@Component({
  selector: 'app-free-text-question',
  templateUrl: './free-text-question.component.html',
  styleUrls: ['./free-text-question.component.scss']
})
export class FreeTextQuestionComponent implements OnInit, OnDestroy, QuestionComponent {

  @Input() questionData: QuestionResponse;
  @Input() questionAnswerInstanceList: QuestionAnswerInstanceResponse[];
  @Input() messageService: MessageService;
  @Input() surveyStateService: SurveyStateService;
  @Input() readonly: boolean;

  answerText: string;
  selectedAnswerOptionId;
  showComponent = false;
  tooltip: string;

  private answerSavedSubscription: Subscription;
  constructor() { }

  ngOnInit() {
    this.showComponent = this.surveyStateService?.checkVisibilityCondition(this.showComponent, this.questionData.dependsOnAnswerOptionIdCollection, this.questionData.id) ?? true;
    this.answerText = this.questionAnswerInstanceList[0] ? this.questionAnswerInstanceList[0].freeTextAnswer : '';
    this.selectedAnswerOptionId = this.questionData.answerCollection[0].answerOptionCollection[0].id;

    this.tooltip = this.questionData.tooltipText;

    if (!this.readonly) {
      this.answerSavedSubscription = this.surveyStateService.answerSaved.subscribe(
        data => {
          if (data.successStatus) {
            this.showComponent = this.surveyStateService.checkVisibilityCondition(this.showComponent, this.questionData.dependsOnAnswerOptionIdCollection, this.questionData.id);
            if (this.showComponent === false) {
              this.answerText = null;
            }
          }
        });
    }
  }

  ngOnDestroy(): void {
    if (!this.readonly) {
      this.answerSavedSubscription.unsubscribe();
    }
  }

  isTextProvided() {
    return this.answerText !== null && this.answerText.length > 0 && /[a-zA-Z]/.test(this.answerText);
  }

  answerSelected(event) {
    let selectedAnswerOptionIdList = [
      { 
        AnswerOptionId: this.selectedAnswerOptionId,
        FreeText: this.answerText 
      } as SelectedOptionRequest
    ];

    this.surveyStateService.saveQuestionAnswersCommand(
      selectedAnswerOptionIdList,
      this.questionData.id);
  }
}
