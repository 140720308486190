import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AccountService } from '../../../../lib/src/services/account.service';
import { environment } from '../../environments/environment';
// import { AccountService } from '../services/account.service';
// import { environment } from 'src/environment';
import { catchError, switchMap } from 'rxjs/operators';
import { of, throwError } from 'rxjs';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private accountService: AccountService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to the api url
        const user = this.accountService.userValue;
        const isLoggedIn = user && user.token;
        const isApiUrl = request.url.startsWith(environment.apiURL);
        if (isLoggedIn && isApiUrl) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${user.token}`
                }
            });
        }

        return next.handle(request).pipe(
          catchError(error => {
              if (error.status === 401) {
                  this.accountService.logout();
              }
              return throwError(error);
          })
      );
    }
}
