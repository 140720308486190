import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AccountService } from '../../../../../lib/src/public-api';

@Component({
  selector: 'app-data-protection',
  templateUrl: './data-protection.component.html',
  styleUrls: ['./data-protection.component.scss']
})
export class DataProtectionComponent implements OnInit {
  isLegalAccepted = false;
  constructor(
    private router: Router,
    private accountService: AccountService,
    private location: Location) { }

  ngOnInit() {
  }

  homeClicked() {
    this.router.navigate(['/']);
  }
}
