import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { QuestionComponent } from '../question.component';
import { QuestionResponse, QuestionAnswerInstanceResponse, SurveyStateService, SelectedOptionRequest } from '../../public-api';

@Component({
  selector: 'app-text-area-question',
  templateUrl: './text-area-question.component.html',
  styleUrls: ['./text-area-question.component.scss']
})
export class TextAreaQuestionComponent implements OnInit, OnDestroy, QuestionComponent {

  @Input() questionData: QuestionResponse;
  @Input() questionAnswerInstanceList: QuestionAnswerInstanceResponse[];
  @Input() messageService: MessageService;
  @Input() surveyStateService: SurveyStateService;
  @Input() readonly: boolean;

  answerText: string;
  selectedAnswerOptionId;
  showComponent = false;
  numberOfRows: number;
  private answerSavedSubscription: Subscription;
  constructor() { }

  ngOnInit() {
    this.showComponent = this.surveyStateService?.checkVisibilityCondition(this.showComponent, this.questionData.dependsOnAnswerOptionIdCollection, this.questionData.id) ?? true;
    this.answerText = this.questionAnswerInstanceList[0] ? this.questionAnswerInstanceList[0].freeTextAnswer : '';
    this.selectedAnswerOptionId = this.questionData.answerCollection[0].answerOptionCollection[0].id;
    const numberOfRows = this.answerText.split('\n').length;
    this.numberOfRows = Math.max(this.questionData.numberInputConfiguration?.numberOfDigits ?? 5, numberOfRows);

    if (!this.readonly) {
      this.answerSavedSubscription = this.surveyStateService.answerSaved.subscribe(
        data => {
          if (data.successStatus) {
            this.showComponent = this.surveyStateService.checkVisibilityCondition(this.showComponent, this.questionData.dependsOnAnswerOptionIdCollection, this.questionData.id);
            if (this.showComponent === false) {
              this.answerText = null;
            }
          }
        });
    }
  }

  ngOnDestroy(): void {
    if (!this.readonly) {
      this.answerSavedSubscription.unsubscribe();
    }
  }

  isTextProvided() {
    return this.answerText !== null && this.answerText.length > 0 && /[a-zA-Z]/.test(this.answerText);
  }

  answerSelected(event) {
    let selectedAnswerOptionIdList = [{ AnswerOptionId: this.selectedAnswerOptionId, FreeText: this.answerText } as SelectedOptionRequest ];
    this.surveyStateService.saveQuestionAnswersCommand(
      selectedAnswerOptionIdList,
      this.questionData.id);
  }
}
