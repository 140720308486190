<div class="container">
  <mat-tab-group selectedIndex="2" style="margin-left: 100px; margin-right: 100px; margin-top: 50px">
    <mat-tab label="2024">
      <ng-template matTabContent>
        <app-users-panel [surveyId]="survey2024Id"></app-users-panel>
      </ng-template>
    </mat-tab>
    <mat-tab label="2025">
      <ng-template matTabContent>
        <app-users-panel [surveyId]="survey2025Id"></app-users-panel>
      </ng-template>
    </mat-tab>

  </mat-tab-group>
</div>
