import { Component, OnInit, OnDestroy } from '@angular/core';
import { GetFilledResponsesPercentageResponse, QuestionGroupResponse, SurveySectionGetResponse, SurveyStateService } from '../../public-api';
// import { environment } from '../../../environments/environment';
// import { SurveySectionGetResponse, QuestionGroupResponse, SurveyStateService, GetFilledResponsesPercentageResponse } from '../../../../../lib/src/public-api';

interface SurveySection {
  section: SurveySectionGetResponse;
  groupId: number;
  percentage: number;
}

@Component({
  selector: 'app-survey-header',
  templateUrl: './survey-header.component.html',
  styleUrls: ['./survey-header.component.scss']
})
export class SurveyHeaderComponent implements OnInit, OnDestroy {
  public CurrentQuestionGroup: QuestionGroupResponse;
  public Sections: SurveySection[];
  public ActiveSection: SurveySectionGetResponse = {};
  public id: number;
  public SUMMARY_ID = -1;
  surveyStateServiceSubscription;
  isOldSurvey = false;
  constructor(private surveyStateService: SurveyStateService) { }

  ngOnInit() {
    this.surveyStateServiceSubscription = this.surveyStateService.surveyInitialised.subscribe(
      data => {
        if (data === true) {
          var sections = this.surveyStateService.GetSurveySections();
          var filledResponsesPercentage = this.surveyStateService.getFilledResponsesPercentage();
          this.setSections(sections, filledResponsesPercentage);
          this.setIsOldSurvey();
        }
      }
    );
    this.surveyStateService.questionGroupLoaded.subscribe(
      data => {
        if (data === true) {
          this.ActiveSection = this.surveyStateService.GetActiveSurveySection();
          this.CurrentQuestionGroup = this.surveyStateService.GetCurrentQuestionGroup();
          var groupId = this.getGroupId(this.ActiveSection);
          this.id = groupId;
        }
      }
    );
    this.surveyStateService.responsesPercentagesChanged.subscribe(
      filledResponsesPercentage => {
        var sections = this.surveyStateService.GetSurveySections();
        this.setSections(sections, filledResponsesPercentage);
      }
    );
  }

  private setSections(sections: SurveySectionGetResponse[], filledResponsesPercentage: GetFilledResponsesPercentageResponse) {
    this.Sections = [];
    for (var section of sections) {
      var groupId = this.getGroupId(section);
      if (groupId == null) {
        continue;
      }
      this.Sections.push({
        section: section,
        groupId: groupId,
        percentage: filledResponsesPercentage.percentageByQuestionGroup[section.id]
      });
    }
  }

  private getGroupId(section: SurveySectionGetResponse): number {
    var distinctGroups = [...new Set(section.questionGroupList.map(x => x.id))];
    if (distinctGroups.length != 1) {
      return null;
    }
    return distinctGroups[0];
  }

  ngOnDestroy(): void {
    this.surveyStateServiceSubscription.unsubscribe();
  }

  radioClicked(ev) {
    var value = ev.value;
    if (value == this.SUMMARY_ID) {
      this.surveyStateService.GoToSummary();
    } else {
      this.surveyStateService.GoToQuestionGroup(value);
    }
  }

  private setIsOldSurvey() {
    // TODO think how to use environment const here
    this.isOldSurvey = this.surveyStateService.isOldSurvey(1);
  }
}
