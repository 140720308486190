import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
// import { AnswerResponse, AnswerOptionResponse, QuestionResponse, QuestionAnswerInstanceResponse } from '../../public-api';
// import { SurveyStateService } from '../../public-api';
import { QuestionComponent } from '../question.component';
import { AnswerOptionResponse, AnswerResponse, QuestionAnswerInstanceResponse, QuestionResponse, SelectedOptionRequest, SurveyStateService } from '../../public-api';
import { Subscription } from 'rxjs';
// import { SurveyStateService } from '../../public-api';
// import { SurveyStateService } from '../../services/surveyState.service';
// import { SurveyStateService } from '../../public-api';
// import { AnswerOptionResponse } from 'projects/lib/src/api/models';
import { Card } from 'primeng/card';

interface ColumnSumValidator {
  greaterThan: number;
  lessThan: number;
  equals: number;
}

interface CellAnswer {
  answer: AnswerResponse,
  answerOption: AnswerOptionResponse
}

@Component({
  selector: 'app-phase-question',
  templateUrl: './phase-question.component.html',
  styleUrls: ['./phase-question.component.scss']
})
export class PhaseQuestionComponent implements OnInit, OnDestroy, QuestionComponent {
  @Input() readonly: boolean;
  @Input() questionData: QuestionResponse;
  @Input() questionAnswerInstanceList: QuestionAnswerInstanceResponse[];
  @Input() messageService: MessageService;
  @Input() surveyStateService: SurveyStateService;

  selectedAnswerOptionId: number;
  selectedAnswerOptionFreeText: string;
  showComponent = false;
  private answerSavedSubscription: Subscription;

  constructor() { }

  ngOnInit() {
    this.showComponent = this.surveyStateService.checkVisibilityCondition(this.showComponent, this.questionData.dependsOnAnswerOptionIdCollection, this.questionData.id);
    this.selectedAnswerOptionId = this.questionAnswerInstanceList.map(a => a.answerOptionSelectedId)[0];
    this.selectedAnswerOptionFreeText = this.questionAnswerInstanceList.map(a => a.freeTextAnswer)[0];
    this.answerSavedSubscription = this.surveyStateService.answerSaved.subscribe(
      data => {
        if (data.successStatus) {
          this.showComponent = this.surveyStateService.checkVisibilityCondition(this.showComponent, this.questionData.dependsOnAnswerOptionIdCollection, this.questionData.id);
          if (this.showComponent === false) {
            this.selectedAnswerOptionId = null;
            this.selectedAnswerOptionFreeText = '';
          }
        }
      });
  }

  answerSelected() {
    if (this.readonly) {
      return
    }
    const freeTextAnswerSlected = this.questionData.answerCollection.filter(a => a.answerOptionCollection[0].id === this.selectedAnswerOptionId)[0].answerOptionCollection[0].isFreeText;
    if (!freeTextAnswerSlected) {
      this.selectedAnswerOptionFreeText = '';
    }
    let selectedOption = [({ answerOptionId: this.selectedAnswerOptionId, freeText: this.selectedAnswerOptionFreeText } as SelectedOptionRequest)];
    if (freeTextAnswerSlected && (this.selectedAnswerOptionFreeText && this.selectedAnswerOptionFreeText.length === 0 || /[a-zA-Z]/.test(this.selectedAnswerOptionFreeText) === false)) {
      selectedOption = [];
    }
    this.surveyStateService.saveQuestionAnswersCommand(
      selectedOption,
      this.questionData.id);
  }

  labelClicked(answerOptionId) {
    if (this.readonly) {
      return
    }
    this.selectedAnswerOptionId = answerOptionId;
    this.answerSelected();
  }

  ngOnDestroy(): void {
    if (this.answerSavedSubscription) {
      this.answerSavedSubscription.unsubscribe();
    }
  }
}
