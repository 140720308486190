import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GetFilledResponsesPercentageResponse, SurveySectionGetResponse } from '../../../../../lib/src/public-api';

export interface PercentageDialogData {
  filledResponsesPercentage?: GetFilledResponsesPercentageResponse;
  firstName: string;
  lastName?: null | string;
  sections: SurveySectionGetResponse[];
}

@Component({
  templateUrl: './percentage-dialog.component.html',
  styleUrls: ['./percentage-dialog.component.scss']
})
export class PercentageDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<PercentageDialogComponent>,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: PercentageDialogData) {}
}
