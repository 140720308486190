import { Component, OnInit, ViewEncapsulation, ViewChild, ComponentFactoryResolver, HostListener, OnDestroy, AfterViewInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { QuestionListDirective, QuestionItem, SurveyStateService, ProgressSpinnerService, AccountService, getQuestions, QuestionComponent } from '../../public-api';
// import { AccountService, getQuestions, ProgressSpinnerService, QuestionComponent, QuestionItem, QuestionListDirective, SurveyStateService } from '../../../../../lib/src/public-api';

@Component({
  selector: 'app-survey-content',
  templateUrl: './survey-content.component.html',
  styleUrls: ['./survey-content.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SurveyContentComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(QuestionListDirective, {static: false}) questionList: QuestionListDirective;
  currentPageQuestions: QuestionItem[];
  // isReadOnlyModeEnabled = environment.readOnlyModeEnabledForSurveyIdList.indexOf(this.accountService.getUser().surveyId) >= 0;
  private answerSavedSubscription: Subscription;
  private questionGroupLoadedSubscription: Subscription;
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private surveyStateService: SurveyStateService,
    private progressSpinnerService: ProgressSpinnerService,
    private messageService: MessageService,
    private accountService: AccountService
  ) { }

  ngOnInit() {
    this.answerSavedSubscription = this.surveyStateService.answerSaved.subscribe(
      data => {
        if (!data.successStatus) {
          this.showError('Save failed');
        }
      });
  }

  ngAfterViewInit() {
    this.questionGroupLoadedSubscription = this.surveyStateService.questionGroupLoaded.subscribe(
      data => {
        if (data === true) {
          this.currentPageQuestions = this.getCurrentPageQuestions();
          this.loadComponent();
        }
      },
      _ => this.showError('Question group load failed.')
    );
  }

  getCurrentPageQuestions() {
    const pageData = this.surveyStateService.GetQuestionAnswersForTheCurrentQuestionGroup();

    return getQuestions(pageData);
  }

  loadComponent() {
    // setTimeout(() => {
      try {
        const viewContainerRef = this.questionList.viewContainerRef;
        viewContainerRef.clear();

        this.currentPageQuestions.forEach(question => {
          const componentFactory = this.componentFactoryResolver.resolveComponentFactory(question.component);
          const componentRef = viewContainerRef.createComponent(componentFactory);
          (componentRef.instance as QuestionComponent).questionData = question.questionData;
          (componentRef.instance as QuestionComponent).questionAnswerInstanceList = question.questionAnswerInstanceList;
          (componentRef.instance as QuestionComponent).messageService = this.messageService;
          (componentRef.instance as QuestionComponent).surveyStateService = this.surveyStateService;
          (componentRef.instance as QuestionComponent).readonly = this.surveyStateService.isOldSurvey(1);
        });
      } catch (error) {
        // Handle the error
        console.error('An error occurred:', error);
      }
    // }, 5000);
  }

  showError(message) {
    this.messageService.clear();
    this.messageService.add({ severity: 'error', summary: 'Error', detail: message, sticky: false });
    this.progressSpinnerService.hide();
  }

  showInfo(message) {
    this.messageService.clear();
    this.messageService.add({ severity: 'info', summary: 'Info', detail: message, sticky: false });
    this.progressSpinnerService.hide();
  }

  getQuestionGroupText() {
    const qg = this.surveyStateService.GetActiveSurveySection();
    return qg ? qg.text : '';
  }

  ngOnDestroy(): void {
    this.answerSavedSubscription.unsubscribe();
    this.questionGroupLoadedSubscription.unsubscribe();
  }
}
