<div class="p-grid surveyGrid">
  <div class="p-col surveyQuestions">
    <div id="container">
      <div class="content">
        <div>
          <form [formGroup]="registrationForm" (ngSubmit)="buttonRegisterClicked()">
            <div class="registerButtonWrapper">
              <div class="input-container">
                <label class="regLabel" for="company"> {{ 'Company name' | translate }}*</label>
                <input pInputText type="text" class="input-width" id="company" formControlName="company">
              </div>

              <div class="input-container">
                <label class="regLabel" for="selfDescription"> {{ 'How do you describe yourself' | translate }}*</label>
                <p-dropdown styleClass="input-width" inputStyleClass="input-width" class="dropDown orderedCategoryDropdownThemeClass input-width" formControlName="selfDescription" [options]="genderOptions">
                  <ng-template pTemplate="selectedItem">
                    <div class="flex align-items-center gap-2" *ngIf="registrationForm.value.selfDescription">
                        <div>{{ registrationForm.value.selfDescription | translate }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                      <div class="flex align-items-center gap-2">
                          <div>{{ item | translate }}</div>
                      </div>
                  </ng-template>
                </p-dropdown>
              </div>

              <div class="input-container">
                <label class="regLabel" for="firstName"> {{ 'First name' | translate }}*</label>
                <input pInputText type="text" id="firstName" class="input-width" formControlName="firstName">
              </div>

              <div class="input-container">
                <label class="regLabel" for="lastName"> {{ 'Last name' | translate }}*</label>
                <input pInputText type="text" id="lastName" class="input-width" formControlName="lastName">
              </div>

              <div class="input-container">
                <label class="regLabel" for="phone"> {{ 'Phone' | translate }}*</label>
                <input pInputText type="tel" id="phone" class="input-width" formControlName="phone">
              </div>

              <div class="input-container">
                <label class="regLabel" for="fax"> {{ 'Fax' | translate }}</label>
                <input pInputText type="tel" id="fax" class="input-width" formControlName="fax">
              </div>

              <div class="input-container">
                <label class="regLabel" for="email"> {{ 'E-Mail' | translate }}*</label>
                <input pInputText type="email" id="email" class="input-width" formControlName="email">
              </div>

              <div class="input-container">
                <label class="regLabel" for="pin"> {{ 'Password' | translate }}*</label>
                <p-password id="pin" type="text" styleClass="input-width" inputStyleClass="input-width" [toggleMask]="true" [feedback]="false" formControlName="password"></p-password>
              </div>

              <div class="input-container">
                <label class="regLabel" for="confirmPassword"> {{ 'Confirm Password' | translate }}*</label>
                <p-password id="pin" type="text" styleClass="input-width" inputStyleClass="input-width" [toggleMask]="true" [feedback]="false" formControlName="confirmPassword"></p-password>
              </div>

              <br />
              <div style="width: 550px; margin: 0 auto; text-align: left;">
                <div style="margin-bottom:20px">
                  <span style="font-weight: bold; text-align: left">{{ 'Please let us know what type of mailing wold you like to receive from Kearney' | translate }}</span>
                </div>
                <!-- <p-radioButton formControlName="eventInvitations" id="eventInvitationsTrue" value="true">
                </p-radioButton>
                <label for="eventInvitationsTrue" class="ml-2">{{'Opt-in'}}</label> -->
                <!-- <p-radioButton formControlName="eventInvitations" id="eventInvitationsFalse" value="false">
                </p-radioButton>
                <label for="eventInvitationsFalse" class="ml-2">{{'Opt-out'}}</label> -->

                <div class="preference-group">
                  <label style="text-align: left">{{ 'Event invitations (including save the dates, agendas, event follow-ups)' | translate }}</label>

                  <div class="radio-group">
                    <div>
                      <p-radioButton formControlName="eventInvitations" id="eventInvitationsTrue" value="true">
                      </p-radioButton>
                      <label for="eventInvitationsTrue" class="ml-2">{{ 'Opt-in' | translate }}</label>
                    </div>
                    <div>
                      <p-radioButton value="false" formControlName="eventInvitations" id="eventOptOut"></p-radioButton>
                      <label for="eventOptOut" class="ml-2">{{ 'Opt-out' | translate }}</label>
                    </div>
                  </div>
                </div>

                <div class="preference-group" style="text-align: left">
                  <label style="text-align: left">{{ 'Ideas and Insights (including newsletters and intellectual capital)' | translate }}</label>
                  <div class="radio-group">
                    <div>
                      <p-radioButton name="ideasAndInsights" value="true" formControlName="ideasAndInsights" id="insightsOptIn"></p-radioButton>
                      <label for="insightsOptIn" class="ml-2">{{ 'Opt-in' | translate }}</label>
                    </div>
                    <div>
                      <p-radioButton name="ideasAndInsights" value="false" formControlName="ideasAndInsights" id="insightsOptOut"></p-radioButton>
                      <label for="insightsOptOut" class="ml-2">{{ 'Opt-out' | translate }}</label>
                    </div>
                  </div>
                </div>

                <div class="preference-group">
                  <label>{{ 'General marketing communications' | translate }}</label>
                  <div class="radio-group">
                    <div>
                      <p-radioButton name="generalMarketing" value="true" formControlName="generalMarketing" inputId="marketingOptIn"></p-radioButton>
                      <label for="marketingOptIn" class="ml-2">{{ 'Opt-in' | translate }}</label>
                    </div>
                    <div>
                      <p-radioButton name="generalMarketing" value="false" formControlName="generalMarketing" inputId="marketingOptOut"></p-radioButton>
                      <label for="marketingOptOut" class="ml-2">{{ 'Opt-out' | translate }}</label>
                    </div>
                  </div>
                </div>

                <div class="preference-group">
                  <label>{{ 'Industry relevant surveys' | translate }}</label>
                  <div class="radio-group">
                    <div>
                      <p-radioButton name="industryRelevantSurveys" value="true" formControlName="industryRelevantSurveys" inputId="surveysOptIn"></p-radioButton>
                      <label for="surveysOptIn" class="ml-2">{{ 'Opt-in' | translate }}</label>
                    </div>
                    <div>
                      <p-radioButton name="industryRelevantSurveys" value="false" formControlName="industryRelevantSurveys" inputId="surveysOptOut"></p-radioButton>
                      <label for="surveysOptOut" class="ml-2">{{ 'Opt-out' | translate }}</label>
                    </div>
                  </div>
                </div>

              </div>
              <br />
              <div class="p-col-12 accept-margin">
                <p-checkbox (onChange)="legalAccepted($event)" binary="true" inputId="binary"></p-checkbox>
                <a [routerLink]="['/legal']"> {{ 'I accept the general terms and conditions' | translate }}</a>
              </div>
              <br />
              <button pButton class="button" type="button" label="{{ 'Register' | translate }}" (click)="buttonRegisterClicked()" style="margin-bottom: 20px;"
                [disabled]='!canRegister() || !isLegalAccepted'></button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
