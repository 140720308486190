import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { PendingChangesService } from '../../services/pending-changes.service';
import { AccountService } from '../../services/account.service';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {

  constructor(private translate: TranslateService,
    private pendingChangesService: PendingChangesService,
    private accountService: AccountService,
    ) { }

  ngOnInit() {
    const url = window.location.href;
    const token = url.split('/').pop().split('?')[0];
    var user = this.accountService.getUser();
    var selectedSurvey = user.surveys.find(s => s.surveyToken === token);
    this.accountService.setCurrentSurveyInstance(selectedSurvey.surveyInstanceId);
   }

  @HostListener('window:beforeunload')
  beforeunload($event: any) {
    if (this.pendingChangesService.hasPendingChanges) {
      return false;
    }
    return true;
  }
}
