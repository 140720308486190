import { ElementRef, Injectable } from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
// import { SlideType } from 'projects/tools/src/public-tools';
import { Observable, from, of, timer } from 'rxjs';
import { delayWhen, map, mergeMap, switchMap, tap } from 'rxjs/operators';
// import { Orientation, SlidesGenerationOptions } from '../../dialogs/slide-export-options/slide-export-options-dialog.component';
// import { ConstantsHelper } from '../../helpers/constants-helper';
// import { ColorsHelper } from '../colors-helper';
// import { SlideItem } from '../models/slide-item';
// import { SlidesExportInterface } from './slides-export-interface';

@Injectable({
  providedIn: 'root'
})
export class PdfGenerationService2 {
  format: 'PNG' | 'JPEG' = 'JPEG';
  mimeType: 'image/png' | 'image/jpeg' = 'image/jpeg';
  // public exportSlide(
  //   element: HTMLElement,
  //   fileName: string,
  //   onElementLoad: (onlyLegend: boolean) => void
  // ): Observable<unknown> {
  //   onElementLoad(false);
  //   const pdf = this.createEmptyPDF();
  //   return this.addElementToPDF(pdf, element, false)
  //     .pipe(tap(() => {
  //       pdf.save(`${fileName}.pdf`);
  //     }));
  // }

  public exportSlides(
    element: HTMLElement[],
    fileName: string,
    onElementLoad: (i: number, onlyLegend: boolean) => void
  ): Observable<unknown> {

    const pdf = this.createEmptyPDF();
    var x = pdf.internal.pageSize.height
    onElementLoad(0, false);

    let observable = this.addElementToPDF(pdf, element[0], 0, false);

    for (let i = 1; i < element.length; i++) {
      observable = observable.pipe(
        tap(() => onElementLoad(i, false)),
        mergeMap((y) => this.addElementToPDF(pdf, element[i], y, false))
      );
    }

    return observable.pipe(tap(() => {
      pdf.save(`${fileName}.pdf`);
    }));
  }

  private createEmptyPDF(): jsPDF {
    return new jsPDF('p', null, [210, 20 * 297]); //'l' : 'p'
  }

  private addCanvasToPDF(canvas: HTMLCanvasElement, pdf: jsPDF, y: number) {
    const pageWidth = 210;
    const pageHeight = 297;

    const maxImagewidth = 0.95 * pageWidth;
    // const maxImageHeight = 0.95 * pageHeight;

    let ratio = canvas.width / maxImagewidth;
    // let ratio = Math.max(canvas.width / maxImagewidth, canvas.height / maxImageHeight);

    if (ratio < 1) {
      ratio = 1;
    }

    const imgWidth = canvas.width / ratio;
    const imgHeight = canvas.height / ratio;
    const marginX = (pageWidth - imgWidth) / 2;
    const marginY = 5; //(pageHeight - maxImageHeight) / 2;

    const dataURL = canvas.toDataURL(this.mimeType);
    pdf.addImage(dataURL, this.format, marginX, y + marginY, imgWidth, imgHeight, undefined, 'FAST');

    return y + imgHeight + marginX
  }

  private addElementToPDF(
    pdf: jsPDF,
    element: HTMLElement,
    y: number,
    addNewPage = true): Observable<number> {

    if (addNewPage) {
      pdf.insertPage(pdf.getNumberOfPages() + 1);
    }

    // const isMarkdownSlide = analysisInfo.slideType === SlideType.StdTemplate || analysisInfo.slideType === SlideType.Section;
    // const isLongTimeoutNeeded = analysisInfo.slideType === SlideType.TelcoTrendsWorldMap;

    let delayTimeout = 500;
    // if (isMarkdownSlide) {
    //   // 500ms for images loading
    //   delayTimeout = 500;
    // } else if (isLongTimeoutNeeded) {
    //   // 5s for map loading due to data fetching
    //   delayTimeout = 5000;
    // }
    // const options = {
    //   margin: 10,
    //   filename: 'summary.pdf',
    //   pagebreak: { before: 'app-matrix-numerical-question' },
    //   image: { type: 'jpeg', quality: 0.98 },
    //   html2canvas: { scale: 1 },
    //   jsPDF: { unit: 'mm', format: 'a3', orientation: 'portrait', pagesplit: false },
    // };

    return of(null)
      .pipe(
        // 500ms for images loading
        delayWhen(_ => timer(delayTimeout)),
        switchMap(() => from(
          html2canvas(element, {
            scale: this.getScaleFromOptions(),
            width: 1284
          })
        )
        ),
        map((canvas) => {
          return this.addCanvasToPDF(canvas as HTMLCanvasElement, pdf, y);
        })
      );
  }

  // private isHorizontalOrientation(orientation: Orientation) {
  //   return orientation.text === 'Horizontal';
  // }

  private getScaleFromOptions() {
    return 2; // options.imageFormat.format === 'PNG' ? 2 : 1;
  }
}
