import { NgModule } from '@angular/core';
import { Routes, RouterModule, mapToCanDeactivate } from '@angular/router';
// import { SurveyComponent } from './surveys/survey/survey.component';
import { UnauthorisedComponent } from './users/unauthorised/unauthorised.component';
// import { ErrorComponent } from './surveys/error/error/error.component';
import { StartPageComponent } from './static/start-page/start-page.component';
import { Competition2024Component } from './static/competition-2024/competition-2024.component';
import { Competition2025Component } from './static/competition-2025/competition-2025.component';
import { RegistrationComponent } from './users/registration/registration.component';
import { LoginComponent } from './users/login/login.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ChangePasswordComponent } from './users/change-password/change-password.component';
import { SetNewPasswordComponent } from './users/set-new-password/set-new-password.component';
// import { SurveySummaryContentComponent } from './surveys/survey-summary-content/survey-summary-content.component';
// import { EndOfSurveyComponent } from './surveys/end-of-survey/end-of-survey.component';
import { ImprintComponent } from './static/imprint/imprint.component';
import { ConfidentialityAgreementComponent } from './static/confidentiality-agreement/confidentiality-agreement.component';
import { PrivacyPolicyComponent } from './static/privacy-policy/privacy-policy.component';
import { DataProtectionComponent } from './static/data-protection/data-protection.component';
import { LandingPageComponent } from './static/landing-page/landing-page.component';
// import { SurveySubmittedGuard } from './surveys/survey-submitted.guard';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { AuthGuard, EndOfSurveyComponent, ErrorComponent, SurveyComponent, SurveySubmittedGuard, SurveySummaryContentComponent } from '../../../lib/src/public-api';
import { UsersPanelContainerComponent } from './admin/users-panel-container/users-panel-container.component';

const routes: Routes = [
  { path: 'legal', component: StartPageComponent },
  { path: 'registration', component: RegistrationComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  { path: 'set-new-password/:guid', component: SetNewPasswordComponent },
  { path: 'login', component: LoginComponent },
  { path: 'summary/:token', component: SurveySummaryContentComponent, canActivate: [AuthGuard] },
  { path: 'surveys/:token', component: SurveyComponent, canActivate: [AuthGuard, SurveySubmittedGuard] },
  { path: 'unauthorized', component: UnauthorisedComponent },
  { path: 'error', component: ErrorComponent },
  // { path: 'l1/:token', component: StartPage0Component, canActivate: [AuthGuard] },
  { path: 'end-of-survey/:token', component: EndOfSurveyComponent, canActivate: [AuthGuard] },
  { path: '2024-competition', component: Competition2024Component, canActivate: [AuthGuard] },
  { path: '2025-competition', component: Competition2025Component, canActivate: [AuthGuard] },
  { path: '', component: LandingPageComponent, canActivate: [AuthGuard] },
  { path: 'imprint', component: ImprintComponent },
  { path: 'confidentiality-agreement', component: ConfidentialityAgreementComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'data-protection', component: DataProtectionComponent },
  { path: 'users-panel', component: UsersPanelContainerComponent, canActivate: [AuthGuard], data: { isAdminRequired: true } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
      { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
  ]
})
export class AppRoutingModule { }
