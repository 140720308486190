import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { MessageService } from 'primeng/api';
import { Subject, throwError } from 'rxjs';
import { catchError, concatMap, takeUntil } from 'rxjs/operators';
import { RegistrationService } from '../../../../../lib/src/api/services';
import { AccountService, ProgressSpinnerService } from '../../../../../lib/src/public-api';

@Component({
  selector: 'competition-2025',
  templateUrl: './competition-2025.component.html',
  styleUrls: ['./competition-2025.component.scss']
})
export class Competition2025Component implements OnInit {
  email = '';
  pin = '';
  isLegalAccepted = false;
  legalIsBlocking = false;
  isReadOnlyModeEnabled = environment.readOnlyModeEnabledForSurveyIdList.indexOf(environment.Competition2025SurveyId) >= 0;

  token = '';
  stopSubscription$ = new Subject();

  constructor(
    private accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router,
    private registrationService: RegistrationService,
    private progressSpinnerService: ProgressSpinnerService,
    private messageService: MessageService
    ) { }

  ngOnInit() {
    // if (this.accountService.getUser().surveyId !== environment.longSurveyId) {
    //   this.router.navigate(['/unauthorized']);
    // }
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: any) {
    if (event.keyCode === 39) {
      this.buttonStartClicked();
    }
  }

  buttonStartClicked() {
      const user = this.accountService.getUser();
      const id = environment.Competition2025SurveyId
      var selectedSurvey = user.surveys.find(s => s.surveyId === environment.Competition2025SurveyId);

      if (!selectedSurvey) {
        return;
      }
      this.accountService.setCurrentSurveyInstance(selectedSurvey.surveyInstanceId);
      if (selectedSurvey.isSurveyFinished) {
        this.router.navigateByUrl(`end-of-survey/${selectedSurvey.surveyToken}`);
      } else {
        this.router.navigateByUrl(`surveys/${selectedSurvey.surveyToken}`);
      }
  }
}
